import PriorityListComparator from './PriorityListComparator';
import NamePropertyComparator from './NamePropertyComparator';
import naturalSortComparator from './NaturalSortComparator';
import CargoPartsComparator from './CargoPartsComparator';
import { CargoQuantityPartsComparator } from '../../../modules/columns/quantity';
import {
    unassignedQtyText,
    unassignedCgoText,
    unassignedChartererText,
    unassignedAreaText,
    unassignedAreaGearedText,
    unassignedAreaGearlessText,
    unassignedDirectionText,
    unassignedLoadZoneText,
    unassignedCategoryText,
    unassignedBuyerText,
} from '../../../constants/gridText';

const defaultGroupSortComparator = (nodeA, nodeB, rowGroupsSorting) => {
    if (rowGroupsSorting.length === 0) {
        return 0;
    }

    switch (nodeA.field) {
        case 'direction':
            return sortByDirection(
                nodeA,
                nodeB,
                getCurrentRowGroupSorting(rowGroupsSorting, 'direction')
            );
        case 'groupName':
        case 'quoted':
            return naturalSortComparator(nodeA.key, nodeB.key);
        case 'cargoQuantityParts':
            return sortByQuantity(nodeA, nodeB);
        case 'cargoParts':
            return sortByCargo(nodeA, nodeB);
        case 'charterer':
        case 'thirdPartyCharterer':
            return sortByCharterer(nodeA, nodeB);
        case 'buyer':
            return sortByBuyer(nodeA, nodeB);
        case 'vesselCategory':
            return sortByVesselCategories(
                nodeA,
                nodeB,
                getCurrentRowGroupSorting(rowGroupsSorting, 'vesselCategory')
            );
        case 'loadZone':
            return sortByLoadZone(
                nodeA,
                nodeB,
                getCurrentRowGroupSorting(rowGroupsSorting, 'loadZone')
            );
        case 'areas':
            return sortByAreas(
                nodeA,
                nodeB,
                getCurrentRowGroupSorting(rowGroupsSorting, 'areas')
            );
        case 'areasGeared':
            return sortByAreas(
                nodeA,
                nodeB,
                getCurrentRowGroupSorting(rowGroupsSorting, 'areasGeared')
            );
        case 'type':
            return sortByType(
                nodeA,
                nodeB,
                getCurrentRowGroupSorting(rowGroupsSorting, 'type')
            );
        default:
            return 0;
    }
};

const getCurrentRowGroupSorting = (rowGroupsSorting, colId) => {
    const rowGroupSorting = rowGroupsSorting.find((rgs) => rgs.colId === colId);

    return rowGroupSorting ? rowGroupSorting.sorting : [];
};

const sortByType = (nodeA, nodeB, sorting) =>
    sorting.findIndex(
        (item) => item.toLowerCase() === nodeA.key.toLowerCase()
    ) -
    sorting.findIndex((item) => item.toLowerCase() === nodeB.key.toLowerCase());

const sortByDirection = (nodeA, nodeB, sorting) =>
    checkDefaultGroupValue(nodeA, nodeB, unassignedDirectionText) ||
    sorting.findIndex(
        (item) => item.toLowerCase() === nodeA.key.toLowerCase()
    ) -
        sorting.findIndex(
            (item) => item.toLowerCase() === nodeB.key.toLowerCase()
        );

const sortByQuantity = (nodeA, nodeB) =>
    checkDefaultGroupValue(nodeA, nodeB, unassignedQtyText) ||
    CargoQuantityPartsComparator(nodeA.key, nodeB.key);

const sortByCargo = (nodeA, nodeB) =>
    checkDefaultGroupValue(nodeA, nodeB, unassignedCgoText) ||
    CargoPartsComparator(nodeA.key, nodeB.key);

const sortByCharterer = (nodeA, nodeB) =>
    checkDefaultGroupValue(nodeA, nodeB, unassignedChartererText) ||
    NamePropertyComparator(nodeA.key, nodeB.key);

const sortByBuyer = (nodeA, nodeB) =>
    checkDefaultGroupValue(nodeA, nodeB, unassignedBuyerText) ||
    NamePropertyComparator(nodeA.key, nodeB.key);

const sortByLoadZone = (nodeA, nodeB, sorting) =>
    checkDefaultGroupValue(nodeA, nodeB, unassignedLoadZoneText) ||
    PriorityListComparator(nodeA.key, nodeB.key, sorting);

const sortByAreas = (nodeA, nodeB, sorting) =>
    checkDefaultGroupValue(nodeA, nodeB, unassignedAreaText) ||
    checkDefaultGroupValue(nodeA, nodeB, unassignedAreaGearlessText) ||
    checkDefaultGroupValue(nodeA, nodeB, unassignedAreaGearedText) ||
    sorting.findIndex(
        (item) => item.toLowerCase() === nodeA.key.toLowerCase()
    ) -
        sorting.findIndex(
            (item) => item.toLowerCase() === nodeB.key.toLowerCase()
        );

const sortByVesselCategories = (nodeA, nodeB, sorting) => {
    const result = checkDefaultGroupValue(nodeA, nodeB, unassignedCategoryText);

    if (result !== 0) {
        return result;
    }

    const nodeASortingIndex = sorting.findIndex(
        (item) => item.toLowerCase() === nodeA.key.toLowerCase()
    );
    const nodeBSortingIndex = sorting.findIndex(
        (item) => item.toLowerCase() === nodeB.key.toLowerCase()
    );

    if (nodeASortingIndex === -1 && nodeBSortingIndex !== -1) {
        return 1;
    }

    if (nodeASortingIndex !== -1 && nodeBSortingIndex === -1) {
        return -1;
    }

    return nodeASortingIndex - nodeBSortingIndex;
};

const checkDefaultGroupValue = (nodeA, nodeB, groupDefaultValue) => {
    if (
        isDefaultGroupValue(nodeA.key, groupDefaultValue) &&
        !isDefaultGroupValue(nodeB.key, groupDefaultValue)
    ) {
        return -1;
    } else if (
        !isDefaultGroupValue(nodeA.key, groupDefaultValue) &&
        isDefaultGroupValue(nodeB.key, groupDefaultValue)
    ) {
        return 1;
    }
    return 0;
};

const isDefaultGroupValue = (key, groupDefaultValue) =>
    key === groupDefaultValue;

export default defaultGroupSortComparator;
