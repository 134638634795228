import LaycanEditor from '../grid/editors/LaycanEditor';
import LaycanFormatter from '../grid/formatters/LaycanFormatter';
import LaycanKeyCreator from '../grid/keyCreators/LaycanKeyCreator';
import LaycanValueGetter from '../grid/valueGetters/LaycanValueGetter';
import {
    LaycanComparator,
    LaycanFromComparator,
    LaycanToComparator,
} from '../grid/comparators/LaycanComparator';

import LocationEditor from '../grid/editors/LocationEditor';
import LocationsFormatter from '../grid/formatters/LocationsFormatter';
import LocationPartsComparator from '../grid/comparators/locationPartsComparator';
import LocationPartsKeyCreator from '../grid/keyCreators/LocationPartsKeyCreator';

import { cargoQuantityPartsHeading } from '_legacy/modules/columns/quantity';
import { durationPartsHeading } from '_legacy/modules/columns/duration';
import { clarksonsBrokerHeadingForOrders } from '_legacy/modules/columns/clarksonsBroker';
import { updatedByHeading } from '_legacy/modules/columns/updatedBy';
import { orderStatusHeading } from '_legacy/modules/columns/orderStatus';

import NamePropertyComparator from '../grid/comparators/NamePropertyComparator';
import CompanyFormatter from '_legacy/modules/shared/columns/formatters/CompanyFormatter';
import CompanyKeyCreator from '_legacy/modules/shared/columns/keyCreators/CompanyKeyCreator';
import ChartererEditor from '../grid/editors/ChartererEditor';
import GroupNameRenderer from '../grid/renderers/GroupNameRenderer';

import ZoneEditor from '../grid/editors/ZoneEditor';
import LoadZoneComparator from '../grid/comparators/LoadZoneComparator';

import TypeFormatter, {
    AllTypeFormatter,
} from '../grid/formatters/TypeFormatter';

import TooltipRenderer from '../grid/renderers/TooltipRenderer';

import suppressOnEdit from '../grid/columnDefaults/suppressOnEdit';
import allowUpAndDownOnEdit from '../grid/columnDefaults/allowUpAndDownOnEdit';
import defaultObjEquals from '../grid/columnDefaults/defaultObjEquals';

import CargoEditor from '../grid/editors/CargoEditor';
import MultipleCargoFormatter from '../grid/formatters/MultipleCargoFormatter';
import CargoPartsComparator from '../grid/comparators/CargoPartsComparator';
import CargoPartsKeyCreator from '../grid/keyCreators/CargoPartsKeyCreator';

import MultipleFreightPortBasisFormatter from '../grid/formatters/MultipleFreightPortBasisFormatter';
import FreightPortBasisEditor from '../grid/editors/FreightPortBasisEditor';
import MultiFreightPortBasisComparator from '../grid/comparators/MultiFreightPortBasisComparator';
import FreightPortBasisKeyCreator from '../grid/keyCreators/FreightPortBasisKeyCreator';

import BrokerEditor from '../grid/editors/BrokerEditor';
import MultipleCompanyFormatter from '../grid/formatters/MultipleCompanyFormatter';
import BrokersComparator from '../grid/comparators/BrokersComparator';
import BrokersKeyCreator from '../grid/keyCreators/BrokersKeyCreator';
import { commonRowAndCellClassRules } from '../grid/classRules/classRules';
import LaycanFromFormatter from '../grid/formatters/LaycanFromFormatter';
import LaycanToFormatter from '../grid/formatters/LaycanToFormatter';

import naturalSortComparator from '../grid/comparators/NaturalSortComparator';
import BlanksFilterRenderer from '../grid/renderers/BlanksFilterRenderer';
import defaultSetFilterParams from '../grid/columnDefaults/defaultSetFilterParams';
import SelectEditor from '_legacy/modules/shared/columns/editors/SelectEditor';

import DefaultDateFormatter from '../grid/formatters/DefaultDateFormatter';
import DefaultDateTimeFormatter from '../grid/formatters/DefaultDateTimeFormatter';
import DateComparator from '../grid/comparators/DateComparator';
import DateEditor from '../grid/editors/DateEditor';

import VisibilityRenderer from '../grid/renderers/VisibilityRenderer';
import QuotedFormatter, {
    QuotedFieldFormatter,
} from '../grid/formatters/QuotedFormatter';
import QuotedKeyCreator from '../grid/keyCreators/QuotedKeyCreator';
import { areasByDataset, getAreasGeared } from '../../models/Areas';
import CommissionEditor from '../grid/editors/CommissionEditor';
import ParcelsEditor from '../grid/editors/ParcelsEditor';

import ThirdPartyBrokerEditor from '../grid/editors/ThirdPartyBrokerEditor';
import MultipleThirdPartyCompanyFormatter from '../grid/formatters/MultipleThirdPartyCompanyFormatter';

import DirectionValueGetter from '../grid/valueGetters/DirectionValueGetter';

import {
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS,
} from '../../models/common/EntityPart';
import CommissionFormatter from '../grid/formatters/CommissionFormatter';
import CommissionComparator from '../grid/comparators/CommissionComparator';
import CommissionKeyCreator from '../grid/keyCreators/CommissionKeyCreator';
import { ratesHeadingForOrders } from '_legacy/modules/columns/rate';

import {
    vesselCategoryHeading,
    vesselNameHeadingForOrders,
} from '_legacy/modules/columns/vessel';
import { thirdPartyOwnerHeadingForOrders } from '_legacy/modules/columns/thirdPartyOwner';
import { thirdPartyChartererHeading } from '_legacy/modules/columns/thirdPatyCharterer';

import {
    unassignedAreaText,
    unassignedAreaGearedText,
    unassignedDirectionText,
    unassignedLoadZoneText,
    unassignedChartererText,
} from '_legacy/constants/gridText';
import { getDataset } from '_legacy/models/Datasets';
import { orderDeliveryTermHeading } from '_legacy/modules/columns/deliveryTerm';
import SalePriceEditor from '../grid/editors/SalePriceEditor';
import { formatSalePrice } from '_legacy/services/SalePriceFormatterService';
import { SalePriceComparator } from '_legacy/modules/columns/quantity/comparators/SalePriceComparator';
import DateFormatter from '../grid/formatters/DateFormatter';
import { buyerHeading } from '_legacy/modules/columns/buyer/heading/buyerHeading';

const isEditableRow = (params) => {
    const isEditable =
        params &&
        params.data &&
        params.data.state !== 'Deleted' &&
        params.data.state !== 'Converted' &&
        params.data.state !== 'Withdrawn' &&
        params.context.canEdit;

    return isEditable;
};

const isEditableNonSpecialisedRow = (params) =>
    isEditableRow(params) && params.context.datasetId !== 13;

const checkboxHeading = {
    colId: 'isSelected',
    pinned: 'left',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    lockPinned: true,
    suppressMenu: true,
    suppressMovable: true,
    lockPosition: 'left',
    suppressColumnsToolPanel: true,
    width: 24,
    minWidth: 35,
    enableCellChangeFlash: false,
};

const privacyHeading = {
    colId: 'privacy',
    headerName: '',
    field: 'privacy',
    editable: false,
    cellRendererFramework: VisibilityRenderer,
    headerClass: 'grid-heading-privacy',
    cellClass: 'grid-cell-privacy',
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    lockPinned: true,
    pinned: 'left',
    suppressMovable: true,
    filter: 'visibilityFilter',
    suppressColumnsToolPanel: true,
    width: 60,
    minWidth: 60,
};

const salePriceHeading = {
    colId: 'salePrice',
    headerName: 'Sale Price',
    field: 'salePrice',
    editable: true,
    cellEditorFramework: SalePriceEditor,
    sortable: true,
    hide: true,
    type: ['privatable'],
    width: 150,
    valueFormatter: (params) => formatSalePrice(params.value),
    comparator: SalePriceComparator,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                filter: 'salePriceFilter',
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    ...defaultSetFilterParams,
                    comparator: SalePriceComparator,
                    refreshValuesOnOpen: true,
                    suppressRemoveEntries: true,
                    cellRenderer: BlanksFilterRenderer,
                    valueFormatter: (params) =>
                        formatSalePrice(params.value, true),
                },
            },
        ],
    },
};

const saleDateHeading = {
    colId: 'saleDate',
    headerName: 'Sale Date',
    field: 'saleDate',
    hide: true,
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 150,
};

const chartererHeading = {
    colId: 'charterer',
    headerName: 'Charterer',
    field: 'charterer',
    editable: isEditableRow,
    valueFormatter: CompanyFormatter,
    cellEditorFramework: ChartererEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    keyCreator: (params) =>
        CompanyKeyCreator({ ...params, defaultValue: unassignedChartererText }),
    enableRowGroup: true,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        cellRenderer: BlanksFilterRenderer,
    },
};

const typeHeading = {
    colId: 'type',
    headerName: 'Type',
    field: 'type',
    editable: isEditableRow,
    valueFormatter: TypeFormatter,
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        values: ['VOY', 'TCT', 'PERIOD', 'COA', 'UNKNOWN'],
        formatValue: AllTypeFormatter,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    width: 67,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
};

const laycanHeading = {
    colId: 'laycan',
    headerName: 'L/C',
    field: 'laycan',
    editable: isEditableRow,
    cellEditorFramework: LaycanEditor,
    valueFormatter: LaycanFormatter,
    valueGetter: LaycanValueGetter,
    comparator: LaycanComparator,
    keyCreator: LaycanKeyCreator,
    equals: defaultObjEquals,
    sort: 'asc',
    filter: 'laycanFilter',
    width: 100,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
};

const laycanFromHeading = {
    colId: 'laycanFrom',
    headerName: 'L/C From',
    field: 'laycan',
    editable: false,
    width: 80,
    sortable: true,
    suppressMenu: true,
    valueFormatter: LaycanFromFormatter,
    comparator: LaycanFromComparator,
    hide: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
};

const laycanToHeading = {
    colId: 'laycanTo',
    editable: false,
    width: 80,
    headerName: 'L/C To',
    field: 'laycan',
    sortable: true,
    suppressMenu: true,
    valueFormatter: LaycanToFormatter,
    comparator: LaycanToComparator,
    hide: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
};

const hireExpiryHeading = {
    colId: 'hireExpiry',
    headerName: 'Hire Expiry',
    field: 'hireExpiry',
    hide: true,
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 150,
};

const commentsHeading = {
    colId: 'comments',
    headerName: 'Comments',
    field: 'comments',
    editable: isEditableRow,
    width: 120,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    cellRenderer: TooltipRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    suppressPaste: false,
};

const dischargeLocationHeading = {
    colId: 'dischargeLocationParts',
    headerName: 'Disch',
    field: 'dischargeLocationParts',
    editable: isEditableRow,
    valueFormatter: LocationsFormatter,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
};

const redeliveryLocationHeading = {
    colId: 'redeliveryLocationParts',
    headerName: 'Redelivery',
    field: 'redeliveryLocationParts',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 120,
    hide: true,
};

const deliveryLocationHeading = {
    colId: 'deliveryLocationParts',
    headerName: 'Delivery',
    field: 'deliveryLocationParts',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 100,
    hide: true,
};

const cargoPartsHeading = {
    colId: 'cargoParts',
    headerName: 'Cgo',
    field: 'cargoParts',
    editable: isEditableRow,
    valueFormatter: MultipleCargoFormatter,
    cellEditorFramework: CargoEditor,
    cellRenderer: (cargo) => TooltipRenderer({ value: cargo.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: CargoPartsComparator,
    equals: defaultObjEquals,
    keyCreator: CargoPartsKeyCreator,
    enableRowGroup: true,
    filter: 'cargoesFilter',
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};

const freightPortBasisHeading = {
    colId: 'freightPortBasis',
    headerName: 'Freight Port Basis',
    field: 'freightPortBasis',
    editable: isEditableRow,
    valueFormatter: MultipleFreightPortBasisFormatter,
    cellEditorFramework: FreightPortBasisEditor,
    cellRenderer: (port) => TooltipRenderer({ value: port.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: MultiFreightPortBasisComparator,
    equals: defaultObjEquals,
    keyCreator: FreightPortBasisKeyCreator,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    hide: true,
};

const thirdPartyBrokersHeading = {
    colId: 'thirdPartyBrokers',
    headerName: 'Broker',
    field: 'thirdPartyBrokers',
    editable: isEditableRow,
    valueFormatter: MultipleThirdPartyCompanyFormatter,
    cellEditorFramework: ThirdPartyBrokerEditor,
    cellEditorParams: {
        blockedCharacters: [SEPARATOR_FORWARD_SLASH],
        seperatorCharacters: [SEPARATOR_PLUS],
    },
    cellRenderer: (broker) => TooltipRenderer({ value: broker.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: BrokersComparator,
    equals: defaultObjEquals,
    keyCreator: BrokersKeyCreator,
    width: 120,
    type: ['privatable', 'rumoured'],
    filter: 'companiesFilter',
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};

const loadLocationHeading = {
    colId: 'loadLocationParts',
    headerName: 'Load',
    field: 'loadLocationParts',
    editable: isEditableRow,
    valueFormatter: LocationsFormatter,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
};

const loadZoneHeading = {
    colId: 'loadZone',
    headerName: 'Load Z',
    field: 'loadZone',
    editable: isEditableNonSpecialisedRow,
    enableRowGroup: true,
    cellEditorFramework: ZoneEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedLoadZoneText;
        }
        return value;
    },
    width: 100,
    comparator: LoadZoneComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};

const areaHeading = {
    colId: 'areas',
    headerValueGetter: (params) =>
        getDataset(params.context.datasetId).headingsMapping['areas'],
    enableRowGroup: true,
    field: 'areas',
    editable: isEditableNonSpecialisedRow,
    sortable: true,
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        valuesByDataset: areasByDataset,
    },
    rowGroupIndex: 0,
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedAreaText;
        }
        return value;
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    width: 100,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        cellRenderer: BlanksFilterRenderer,
    },
};

const areaGearedHeading = {
    colId: 'areasGeared',
    headerName: 'Area Geared',
    enableRowGroup: true,
    field: 'areasGeared',
    editable: isEditableRow,
    sortable: true,
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        valuesByDataset: getAreasGeared,
    },
    rowGroupIndex: 0,
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedAreaGearedText;
        }
        return value;
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    width: 100,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        cellRenderer: BlanksFilterRenderer,
    },
    hide: true,
};

const stateHeading = {
    colId: 'state',
    headerName: 'State',
    field: 'state',
    editable: false,
    sortable: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 67,
};

const brokerHeading = {
    colId: 'brokers',
    headerName: 'Broker',
    field: 'brokers',
    editable: isEditableRow,
    valueFormatter: MultipleCompanyFormatter,
    cellEditorFramework: BrokerEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: BrokersComparator,
    equals: defaultObjEquals,
    keyCreator: BrokersKeyCreator,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};

// We need this header in place so that we can use setDataValue() methods for the privateFields (cell privacy)
// column. Otherwise, it's ignored. It is not displayed and cannot be picked from column selector.
//
const privateFieldsHeading = {
    headerName: 'Private Fields',
    field: 'privateFields',
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true,
};

const rumouredHeading = {
    colId: 'rumoured',
    headerName: 'Rumoured',
    field: 'rumoured',
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true,
};

const offersHeading = {
    colId: 'offers',
    headerName: 'Offers',
    field: 'offers',
    editable: isEditableRow,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    cellRenderer: TooltipRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    suppressPaste: false,
};

const groupName = {
    headerName: 'Group',
    field: 'groupName',
    editable: false,
    cellRendererFramework: GroupNameRenderer,
    enableRowGroup: true,
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
};

const privateCommentsHeading = {
    headerName: 'Private Comments',
    field: 'privateComments',
    editable: isEditableRow,
    cellRenderer: TooltipRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    suppressPaste: false,
};

const reportedDateHeading = {
    colId: 'reportedDate',
    headerName: 'Rptd Date',
    field: 'reportedDate',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    width: 85,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
};

const updatedTimeHeading = {
    colId: 'updatedDateTime',
    headerName: 'Updated On',
    field: 'lastUpdatedDateTimeByUser',
    valueFormatter: DefaultDateTimeFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    width: 132,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    hide: true,
};

const quotedHeading = {
    colId: 'quoted',
    headerName: 'Quoted',
    field: 'quoted',
    editable: isEditableRow,
    valueFormatter: QuotedFormatter,
    cellEditorFramework: SelectEditor,
    keyCreator: QuotedKeyCreator,
    enableRowGroup: true,
    cellEditorParams: {
        values: ['Y', 'N'],
        formatValue: QuotedFieldFormatter,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    width: 67,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
    },
    type: ['privatable'],
};

const commissionHeading = {
    colId: 'commission',
    headerName: 'Commission',
    field: 'commission',
    editable: isEditableRow,
    cellEditorFramework: CommissionEditor,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    cellRenderer: (commission) =>
        TooltipRenderer({ value: commission.valueFormatted }),
    valueFormatter: CommissionFormatter,
    equals: defaultObjEquals,
    keyCreator: CommissionKeyCreator,
    comparator: CommissionComparator,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 100,
};

const parcelsHeading = {
    colId: 'parcels',
    headerName: 'Parcels',
    field: 'parcels',
    editable: isEditableRow,
    cellEditorFramework: ParcelsEditor,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 100,
    hide: true,
};

const directionHeading = {
    colId: 'direction',
    headerName: 'Direction',
    field: 'direction',
    editable: false,
    valueGetter: DirectionValueGetter,
    enableRowGroup: true,
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedDirectionText;
        }
        return value;
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 67,
};

const minRedelivery = {
    colId: 'minRedelivery',
    headerName: 'Min Redel',
    field: 'minRedelivery',
    headerTooltip: 'Read-only, auto-calculated based on L/C and firm Duration',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 85,
    hide: true,
};

const maxRedelivery = {
    colId: 'maxRedelivery',
    headerName: 'Max Redel',
    field: 'maxRedelivery',
    headerTooltip: 'Read-only, auto-calculated based on L/C and firm Duration',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 85,
    hide: true,
};
const createdDateHeading = {
    colId: 'createdDateTime',
    headerName: 'Created Date',
    field: 'createdDateTime',
    valueFormatter: DateFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
};

const defaults = [
    checkboxHeading,
    privacyHeading,
    groupName,
    reportedDateHeading,
    updatedTimeHeading,
    updatedByHeading,
    chartererHeading,
    typeHeading,
    cargoQuantityPartsHeading,
    vesselNameHeadingForOrders,
    salePriceHeading,
    saleDateHeading,
    cargoPartsHeading,
    orderDeliveryTermHeading,
    deliveryLocationHeading,
    loadLocationHeading,
    loadZoneHeading,
    dischargeLocationHeading,
    redeliveryLocationHeading,
    freightPortBasisHeading,
    laycanHeading,
    laycanFromHeading,
    laycanToHeading,
    durationPartsHeading,
    hireExpiryHeading,
    vesselCategoryHeading,
    commentsHeading,
    privateCommentsHeading,
    offersHeading,
    areaHeading,
    areaGearedHeading,
    brokerHeading,
    thirdPartyChartererHeading,
    thirdPartyBrokersHeading,
    clarksonsBrokerHeadingForOrders,
    buyerHeading,
    privateFieldsHeading,
    rumouredHeading,
    quotedHeading,
    commissionHeading,
    directionHeading,
    parcelsHeading,
    stateHeading,
    orderStatusHeading,
    minRedelivery,
    maxRedelivery,
    ratesHeadingForOrders,
    thirdPartyOwnerHeadingForOrders,
    createdDateHeading,
];

const fakeHeadingDefaults = {
    1: defaults,
    6: defaults,
    98: defaults,
};

export { defaults as OrderHeadings };

export default class GroupHeadingApi {
    static getGroupHeadingDefaults() {
        return fakeHeadingDefaults;
    }

    static ensureGroupsHaveDefaults(groupIds) {
        const defaultIds = [1, 6, 98];
        const noOfDefaults = defaultIds.length;
        for (let index = 0; index < groupIds.length; index++) {
            const groupId = groupIds[index];

            if (!fakeHeadingDefaults.hasOwnProperty(groupId)) {
                let headingId = undefined;

                if (index < noOfDefaults) {
                    headingId = defaultIds[index];
                } else {
                    const randomId = Math.floor(
                        Math.random() * Math.floor(noOfDefaults)
                    );
                    headingId = defaultIds[randomId];
                }

                fakeHeadingDefaults[groupId] = fakeHeadingDefaults[headingId];
            }
        }
    }
}
