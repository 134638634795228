export const blankKeyText = '(BLANKS)';
export const unknownKeyText = 'UNKNOWN';

export const unassignedQtyText = 'UNASSIGNED QTY';
export const unassignedCgoText = 'UNASSIGNED CGO';
export const unassignedChartererText = 'UNASSIGNED CHARTERER';
export const unassignedBuyerText = 'UNASSIGNED BUYER';
export const unassignedAreaText = 'UNASSIGNED AREA';
export const unassignedAreaGearlessText = 'UNASSIGNED AREA GEARLESS';
export const unassignedAreaGearedText = 'UNASSIGNED AREA GEARED';
export const unassignedDirectionText = 'UNASSIGNED DIRECTION';
export const unassignedLoadZoneText = 'UNASSIGNED LOAD ZONE';
export const unassignedCategoryText = 'UNASSIGNED CATEGORY';

export const isDefaultOrBlankValue = (value) =>
    !value ||
    value.length === 0 ||
    value === blankKeyText ||
    value === unassignedQtyText ||
    value === unassignedCgoText ||
    value === unassignedChartererText ||
    value === unassignedAreaText ||
    value === unassignedAreaGearlessText ||
    value === unassignedAreaGearedText ||
    value === unassignedDirectionText ||
    value === unassignedLoadZoneText ||
    value === unassignedCategoryText ||
    value === unassignedBuyerText;
